<template>
    <div id="bg">
        <div class="search">
            <div class="search-box">
                <span class="search-box-title">订单号：</span>
                <el-input v-model="search.order" placeholder="请输入订单号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">银商账号：</span>
                <el-input v-model="search.ys_account" placeholder="请输入银商账号" size="medium" clearable></el-input>
            </div>
            <div class="search-box">
                <span class="search-box-title">类型：</span>
                <el-select v-model="search.type" filterable placeholder="全部" size="medium" clearable>
                    <el-option key="-1" label="全部" value="-1"></el-option>
                  <el-option key="0" label="上分" value="0"></el-option>
                  <el-option key="1" label="追分" value="1"></el-option>
                  <el-option key="2" label="VIP消耗" value="2"></el-option>
                  <el-option key="3" label="钱包消耗" value="3"></el-option>
                  <el-option key="4" label="用户追分" value="4"></el-option>
                </el-select>
            </div>
            <div class="search-box">
                <span class="search-box-title">时间：</span>
                <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="YYYY-MM-DD"></el-date-picker>
            </div>
            <div class="search-box">
                <el-button type="primary" size="medium" @click="(pager.page = 1),getList()">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
            <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_uid" label="银商ID" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="ys_username" label="银商账号" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
                <template #default="scope">
                    <div v-if="scope.row.type == 1 " :style="{color: '#F33333' }">追分</div>
                    <div v-if="scope.row.type == 0 " :style="{color: '#33C933'}">上分</div>
                    <div v-if="scope.row.type == 2 " :style="{color: '#F33333' }">VIP消耗</div>
                    <div v-if="scope.row.type == 3 " :style="{color: '#F33333'}">钱包消耗</div>
                    <div v-if="scope.row.type == 4 " :style="{color: '#33C933'}">用户追分</div>
                </template>
            </el-table-column>
            <el-table-column prop="addtime" label="时间" align="center" :show-overflow-tooltip="true">
              <template #default="scope">
                <span>{{timeToDate(scope.row.addtime)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="金额" align="center" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="price_new" label="操作后金额" align="center" :show-overflow-tooltip="true"></el-table-column>
        </el-table>
        <page :pager="pager" @query="getList()" />
    </div>
  </template>
  
  <script>
  import { getList } from "@/api/ys/tradLog";
  import page from "@/components/page";
  export default {
    name: "messageLog",
    components: {
        page,
    },
    data() {
      return {
        search: {
        //搜索
        order: "",
        type: "-1",
        ys_account: "",
        searchDate: [], //时间
      },
        pager: { total: 0, page: 1, rows: 10 },
        tableData: [], //数据
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      timeToDate(time){
        if (time == 0){
          return "-"
        }
        return this.$common.timeToDate(time)
      },
      //获取消息列表
      getList() {
        getList([
            { key: "page", val: this.pager.page - 1 },
            { key: "row", val: this.pager.rows },
            { key: "orderNum", val: this.search.order },
            { key: "ysAccount", val: this.search.ys_account },
            { key: "type", val: this.search.type },
            { key: "begin_time", val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[0] : "" },
            { key: "end_time",val: this.search.searchDate && this.search.searchDate.length > 0 ? this.search.searchDate[1] : "" },
        ])
          .then((res) => {
            if (res.code == 0) {
              if (this.pager.page == 1){
                this.pager.total = res.data.total;
              }
                this.tableData = res.data.list;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style scoped>

  </style>